.header {
    @apply absolute w-full;

    .logo{
        transition: opacity 0.4s ;
        @apply w-64;
        &:hover{
            @apply opacity-30 ;
        }
    }
}

.langues {
    @apply flex uppercase justify-center font-ttfirsneuedemibold;

    &__item {
        @apply px-1;

        // &:hover {
        //     @apply text-yellow-500;
        // }

        &--active {
            @apply text-yellow-500;
        }
    }
}

.social {
    @apply flex;

    &__item {
        @apply bg-gray-htg text-white text-center w-6 h-6 md:w-8 md:h-8 flex justify-center items-center mx-1 md:mx-2 transition ease-in-out duration-500 rounded-none ;

        &:hover {
            @apply bg-yellow-500;
        }
    }
}
