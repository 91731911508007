.hyphens-auto{
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
}
.hyphens-none{
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    -o-hyphens: none;
    hyphens: none;
}
.hyphens-manual{
    -webkit-hyphens: manual;
    -moz-hyphens: manual;
    -ms-hyphens: manual;
    -o-hyphens: manual;
    hyphens: manual;
}