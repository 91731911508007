.bloc-square{
    @apply relative flex items-center justify-center overflow-hidden aspect-square  ;

    &.focus-actu{
        @apply border-8 border-white ;
    }

    &__background{
        @apply absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover; ;
    }
    &__noisy{
        @apply absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover; 
        background-image: url('../../../img/back.png') ;
    }

    &__statut{

        &--reported{
            @apply bg-yellow-report  text-sm w-fit pt-1 pb-1 px-3 text-white  uppercase mb-2 ;
        }

        &--canceled{
            @apply bg-red-canceled  text-sm w-fit pt-1 pb-1 px-3 text-white  uppercase mb-2 ;
        }

        &--full{
            @apply bg-blue-full  text-sm w-fit pt-1 pb-1 px-3 text-white  uppercase mb-2 ;
        }
    
    }

    &__infos{
        @apply absolute max-w-full p-4 break-words transition-all duration-500 ease-in-out transform text-white text-center flex flex-col justify-center items-center ;
    }

    &__date{
        @apply font-ttfirsneueblack text-2xl leading-none   ;
    }

    &__heure{
        @apply font-ttfirsneuebold mb-2 ;
    }

    &__label{
        @apply text-base uppercase ;
    }
    &__style{
        @apply text-base uppercase ;
    }
    &__title{
        @apply font-ttfirsneuelight text-3xl md:text-xl 2xl:text-4xl ;
    }
    &__title2{
        @apply font-ttfirsneuelight text-xl xl:text-4xl ;
    }


    &:hover{
        .bloc-square__background{
            @apply scale-125;
        }
        .bloc-square__noisy{
            @apply opacity-0;
        }
        .bloc-square__infos{
            @apply scale-0;
        }
    }

    &__0{
       @apply  lg:row-span-2 lg:col-span-2 aspect-square ;

       .bloc-square__title{
            @apply lg:text-7xl ;
       }
    }

    &__1{
       @apply  lg:col-span-2 aspect-square lg:aspect-[2/1] ;

       .bloc-square__title{
            @apply lg:text-5xl ;
       }
    }

}


