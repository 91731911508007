.btnblack {
    @apply flex justify-center transition duration-500 ease-in-out;

    &:hover{
            .btnblack__link{
                    @apply text-yellow-htg;
            }
    }

    &__link {
        @apply w-fit h-14 flex flex-row transition ease-in-out duration-500 text-white;
    }

    &__left {
        @apply w-5 bg-no-repeat bg-right bg-[url('/img/ui/back_button_left.svg')];
    }

    &__right {
        @apply w-5 bg-no-repeat bg-left bg-[url('/img/ui/back_button_right.svg')];
    }
}
