input {
    display: none;
  }
  
  .menuOpen {
    cursor: pointer;
    transition: opacity 0.4s ;

    &:hover{
      @apply opacity-30 ;
    }
  }
  
//   .menuOpen:hover .open:before {
//     top: -9px;
//   }
  
//   .menuOpen:hover .open:after {
//     top: 5px;
//   }
  
  .menu {
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .menu label {
    cursor: pointer;
  }
  
  .menu .menuContent {
    position: relative;
    top: 50%;
    font-size: 54px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: -170px;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
  }
  
  .menu ul li a {
    display: block;
    color: white;
    text-decoration: none;
    transition: color 0.2s;
    font-family: Trebuchet MS;
    text-transform: uppercase;
    padding: 10px 0;
  }
  
  .menu ul li a:hover {
    color: #ff8702;
  }
  
  .menu ul li:hover {
    background: white;
  }
  
  .menuEffects {
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    transition: opacity 0.2s, visibility 0.2s;
  }
  
  .menuEffects ul {
    transform: translateY(0%);
    transition: all 0.2s;
  }
  
  #menuToggle:checked ~ .menuEffects {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s;
  }
  
  #menuToggle:checked ~ .menuEffects ul {
    opacity: 1;
  }
  
  #menuToggle:checked ~ .menuOpen .open {
    background-color: transparent;
  }
  
  #menuToggle:checked ~ .menuOpen .open:before {
    content: "";
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  
  #menuToggle:checked ~ .menuOpen .open:after {
    content: "";
    background-color: white;
    transform: rotate(-45deg);
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
  }
  
  #menuToggle:not(:checked) ~ .menuEffects ul {
    transform: translateY(-30%);
  }
  
  .drop {
    // background-color:#f00;
    position:relative;
    top:-1px;

    // @apply bg-red-500 ;

    &__link{
      @apply py-1 md:py-2 text-sm md:text-xl 2xl:text-2xl ;
    }
    &__plus{
      @apply font-ttfirsneueblack text-3xl mt-2 ;
    }
}