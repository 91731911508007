.hallSection{
    @apply bg-yellow-htg pb-28 pt-16 ;
    
    &__container {
        @apply container mx-auto ;
    }
}

.hallGrid {
    @apply  grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 py-8;

    &__item {
        @apply flex justify-center mb-12 lg:mb-0;


        &:hover{
            .hallGrid__picto {
                @apply opacity-30 ;
            }
        }

    }

    &__picto {
        transition: opacity 0.4s ;
        
        @apply w-32 h-32 bg-white rounded-t-full bg-center bg-no-repeat bg-auto;
    }

    &__title {
        @apply w-32 bg-gray-htg text-white text-center uppercase text-sm py-2;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 36px;
            height: 36px;
            right: 0;
            top: -100% ;
            @apply bg-gray-htg ;
        }
        &:after {
            content: "";
            position: absolute;
            width: 36px;
            height: 36px;
            left: 0;
            top: 100% ;
            @apply bg-gray-htg ;
        }
    }
}


.focus{
    @apply container mx-auto pb-4 lg:pb-28 pt-16 px-4 lg:px-0 ;

    &__grid{
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-0 py-8 ;

        &__element{
           @apply bg-cover bg-center ;
        }
    }
}

.lieu{

    @apply relative flex items-center justify-center overflow-hidden aspect-[3/2] cursor-pointer ;

    &__mask{
        -webkit-mask-image: url('../img/index/mask.svg');
        mask-image: url('../img/index/mask.svg');
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        @apply aspect-[3/2] bg-cover bg-center flex justify-center items-center ;
    }

    &__text{
        @apply absolute font-ttfirsneuelight text-white text-3xl md:text-5xl xl:text-7xlbis cursor-pointer transition-all duration-500 ease-in-out transform;
    }

    &__background{
        @apply  absolute w-full h-full transition-all duration-500 ease-in-out transform bg-center bg-cover  ;
    }

    &:hover{
        .lieu__text{
            @apply scale-0;
        }
        .lieu__background{
            @apply scale-125;
        }
    }

}

#scroll_to_top {
    position: fixed;
    bottom: 0px;
    right: 25px;
    display: none;
    @apply bg-black text-white pt-4 px-4 cursor-pointer ;
  }