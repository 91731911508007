.error404{
    @apply max-w-5xl mx-auto pb-16 md:pb-28 pt-40 md:pt-56 flex flex-col items-center justify-center ;

    &__title{
        @apply font-ttfirsneuethin text-8xl lg:text-404xl leading-none text-center ;
    }
    &__subtitle{
        @apply font-ttfirsneuethin text-5xl lg:text-8xl leading-none mb-4 text-center ;
    }
    &__p{
        @apply font-ttfirsneuebold text-lg text-center ;

      
    }

}