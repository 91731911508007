.team{

    @apply mb-4 text-xl flex ;

    &__name{
        @apply font-ttfirsneuebold ;
    }

    &__role{
        @apply leading-none text-base ;
    }

    &__button{
        @apply relative flex flex-col items-center justify-center px-2  ;


        &__ico{
            transition: opacity 0.3s ;
        }

        &:hover{

            .team__button__ico{
                @apply opacity-50 ;
            }

        }

    }

    // &__roles{
    //     @apply flex flex-col items-center justify-center group px-2 ;
    // }
}