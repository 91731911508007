.filters {
    @apply flex flex-col md:flex-row justify-center content-center py-6 cursor-pointer;

    &__btn {
        @apply pt-2 pb-1 px-4 uppercase mx-2 my-1 md:my-0 text-xs font-ttfirsneuedemibold text-center border-2 border-black;
        color: black;
        display: block;
        position: relative;
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);

        &:hover {
            color: #fff !important;
            background-color: transparent;
            border-color: #fec940;

            &:before {
                left: 0%;
                right: auto;
                width: 100%;
            }
        }
        &:before {
            display: block;
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            width: 0px;
            z-index: -1;
            content: "";
            background: #fec940;
            transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        }

        &--active {
            @apply bg-yellow-htg border-yellow-htg text-white;
        }

        &--disabled {
            @apply border-gray-200 text-gray-200;
        }
    }
}