.virtualvisits{
    @apply container mx-auto py-12 flex flex-col md:flex-row ;

    &__arroyLeft{
        transition: opacity 0.4s ;
        @apply w-full md:w-16 flex items-center justify-center md:justify-start mb-4 md:mb-0 ;
        &:hover{
            @apply opacity-30 ;
        }
    }
    &__arroyRight{
        transition: opacity 0.4s ;
        @apply w-full md:w-16 flex items-center justify-center md:justify-end mt-4 md:mt-0 ;

        &:hover{
            @apply opacity-30 ;
        }
    }
}