@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@fortawesome/fontawesome-free/css/all.css';
@import "_header";
@import "_index";
@import "_wysiwig";
@import "_programmation";
@import "_vhseffect";
@import "_footer";
@import "_menu";
@import "_teams";
@import "_events";
@import "_error404";
@import "_vr";
@import "_error503";
@import "_splide";
@import "components/events/bloc.scss";
@import "components/ui/buttons/black";
@import '~@splidejs/splide/dist/css/splide.min.css';

@import "simple-lightbox";

@font-face {
    font-family: "TT Firs Neue ExtraLight";
    src: url("../fonts/TTFirsNeue-ExtraLight.woff");
}

@font-face {
    font-family: "TT Firs Neue Light";
    src: url("../fonts/TTFirsNeue-Light.woff");
}

@font-face {
    font-family: "TT Firs Neue Regular";
    src: url("../fonts/TTFirsNeue-Regular.woff");
}

@font-face {
    font-family: "TT Firs Neue DemiBold";
    src: url("../fonts/TTFirsNeue-DemiBold.woff");
}

@font-face {
    font-family: "TT Firs Neue Bold";
    src: url("../fonts/TTFirsNeue-Bold.woff");
}

@font-face {
    font-family: "TT Firs Neue Black";
    src: url("../fonts/TTFirsNeue-Black.woff");
}

@font-face {
    font-family: "TT Firs Neue Thin";
    src: url("../fonts/TTFirsNeue-Thin.woff");
}
