.error503{
    @apply max-w-5xl mx-auto flex flex-col items-center justify-center h-screen ;

    &__subtitle{
        @apply font-ttfirsneuethin text-5xl lg:text-8xl leading-none text-center ;
    }
    &__p{
        @apply font-ttfirsneuebold text-lg text-center mt-4 ;
    }

}