@tailwind base;
@tailwind components;
@tailwind utilities;

.wysiwig{

    p{
        @apply mb-4 ;
    }


    h1{
        @apply text-6xl xl:text-8xl font-ttfirsneuethin mb-4 ;
    }

    h2{
        @apply text-2xl font-ttfirsneuebold mb-4 uppercase ;
    }

    span{
        &.chapo{
            @apply text-lg font-ttfirsneuebold  ;
        }
    }
}