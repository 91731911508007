.footer {
    @apply bg-black text-white py-12;

    &__logo {

        transition: opacity 0.4s ;

        @apply w-full md:w-64 max-w-3xl text-center flex justify-center;

        &:hover{
            @apply opacity-30 ;
        }

        // a {
        //     @apply flex justify-center lg:justify-start;
        // }
    }

    &__cols {
        @apply flex flex-col lg:flex-row;

        &__colLeft {
            @apply flex-auto w-full lg:w-1/4 lg:text-left text-center p-12;
        }

        &__colRight {
            @apply flex-auto  w-full md:w-1/4 lg:text-left text-center p-12;
        }
    }

    &__mentions {
        @apply flex flex-col lg:flex-row justify-center mt-10 uppercase text-xs text-center;
    }

    &__link {
        @apply underline-offset-6 break-words;

        --s: 0.1em; /* the thickness of the line */
        --c: #fec940; /* the color */

        padding-bottom: var(--s);
        background: linear-gradient(90deg, var(--c) 50%, #000 0) calc(100% - var(--_p, 0%)) / 200% 100%,
            linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
        -webkit-background-clip: text, padding-box;
        background-clip: text, padding-box;
        transition: 0.5s;

        &:hover {
            @apply text-yellow-400;
            --_p: 100%;
        }

        &--active {
            @apply text-yellow-400 underline;
        }
    }

    .social {
        @apply flex justify-center mt-10 md:mt-0;

        &__item {
            @apply bg-white text-black text-center w-12 h-12 flex justify-center items-center mx-2;

            &:hover {
                @apply bg-yellow-500;
            }
        }
    }
}

.link {
    --s: 0.1em; /* the thickness of the line */
    --c: #fec940; /* the color */

    padding-bottom: var(--s);
    background: linear-gradient(90deg, var(--c) 50%, #000 0) calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
    -webkit-background-clip: text, padding-box;
    background-clip: text, padding-box;
    transition: 0.5s;

    &:hover {
        @apply text-yellow-400;
        --_p: 100%;
    }
}
